import axios from "axios";

import { URL_FIREBASE, API_KEY_FIREBASE, URL_CHAT } from "../../constants";

const HEADERS = {
  "Content-Type": "application/json",
};

/**
 * Function Verify Custom Token
 * @param {json} body
 */
export async function verifyCustomToken(body, brand) {
  try {
    console.log("[send-custom-token]: ", body);
    const response = await axios.post(
      `${URL_FIREBASE}/verifyCustomToken?key=${API_KEY_FIREBASE}`,
      body,
      { headers: HEADERS }
    );
    console.log("[success-custom-token]:", response.data.idToken);
    return { status: true, id_token: response.data.idToken };
  } catch (error) {
    if (error.response) {
      console.log("[error-business]: ", error.response.data);
      return {
        status: false,
        message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!`,
      };
    } else if (error.request) {
      console.log("[error-request]:", error.request);
      return {
        status: false,
        message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!`,
      };
    } else {
      console.log("[error-api]:", error.request);
      return {
        status: false,
        message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!`,
      };
    }
  }
}
