import IP from "ip";
import Bowser from "bowser";
import JWT from "jsonwebtoken";

import { SECRET_KEY, BRAND_NAME } from "../../constants";

/**
 * Function Authorize
 * @param {obj} props
 */
export const isAuthorizated = () => {
  const ip = IP.address();
  const token = localStorage.getItem("token");
  const browserParse = Bowser.getParser(window.navigator.userAgent);
  const browser = browserParse.getBrowserName();

  return JWT.verify(token, SECRET_KEY, function (err, decoded) {
    if (err) {
      localStorage.removeItem("token");
      return false;
    }

    if (!(browser === decoded.data.browser) && ip === decoded.data.ip) {
      localStorage.removeItem("token");
      return false;
    }

    return true;
  });
};

/**
 * Function Create Token
 * @param {obj}     idToken
 * @param {json}    customToken
 * @param {int}     phone
 * @param {string}  brand
 * @param {string}  partner
 */
export function createToken(idToken, customToken, phone, brand, partner) {
  const browserParse = Bowser.getParser(window.navigator.userAgent);
  const browser = browserParse.getBrowserName();
  const ip = IP.address();

  return JWT.sign(
    {
      data: {
        token: idToken,
        customToken: customToken,
        phone: phone,
        browser: browser,
        brand: brand ? brand : BRAND_NAME,
        partner: partner ? partner : "FS",
        ip: ip,
      },
    },
    SECRET_KEY,
    { expiresIn: "1h" },
    (err, token) => {
      if (err) {
        return false;
      }

      localStorage.setItem("token", token);
    }
  );
}

/**
 * Function Validate Token
 * @param {string} type
 * @param {string} brand
 */
export function validateToken(type) {
  const token = localStorage.getItem("token");

  return JWT.verify(token, SECRET_KEY, function (err, decoded) {
    if (err) {
      return false;
    }

    switch (type) {
      case "token":
        return decoded.data.token;
      case "phone":
        return decoded.data.phone;
      case "customToken":
        return decoded.data.customToken;
      case "brand":
        return decoded.data.brand;
      case "partner":
        return decoded.data.partner;
      default:
        return decoded.data.token;
    }
  });
}

/**
 * Function Remove Token
 */
export function removeToken() {
  const token = localStorage.getItem("token");

  return JWT.verify(token, SECRET_KEY, function (err) {
    if (err) {
      return false;
    }

    localStorage.removeItem("token");
    return (window.location = "/");
  });
}
