import axios from 'axios'
import uuidv4 from 'uuid/v4'
import {
    URL_BACKEND,
    REQUESTER_NAME,
    BRAND_NAME,
    URL_CHAT
} from '../../constants'

const HEADERS = {
    'Content-Type': 'application/json',
    'X-FS-Correlation-ID': uuidv4(),
    'X-FS-Requester-Name': REQUESTER_NAME,
    'X-FS-Brand-Name': BRAND_NAME
}

/**
 * Function Subscribe Serial
 * @param {json} body
 */
export async function subscribeSerial(body){
    try {
        console.log('[send-subscribe-serial]:', body);
        const response = await axios.post(`${URL_BACKEND}/bff/api/v2/subscription/subscribe-serial`, body, { headers: HEADERS })
        console.log('[success-subscribe-serial]:', response.data);
        return { status: true };
    } catch (error) {
        if (error.response) {
            console.log('[error-business]: ', error.response.data);
            return { status: false, message: `Ops, esse serial não foi encontrado. Clique ${URL_CHAT} para falar conosco!` };
        }
        
        if (error.request) {
            console.log('[error-request]:', error.request)
            return { status: false, message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!` };
        }

        console.log('[error-api]:', error.request)
        return { status: false, message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!` };
    }
}

/**
 * Function Activate Pre Sale 
 * @param {json} body
 */
export async function activatePresale(body){
    try {
        console.log('[send-activate-pre-sale]:', body);
        const response = await axios.post(`${URL_BACKEND}/bff/api/v2/pre-sale/activate-presale`, body, {headers: HEADERS });
        console.log('[success-activate-pre-sale]:', response.data);
        return { status: true };
    } catch (error) {
        if (error.response) {
            console.log('[error-business]: ', error.response.data);
            return { status: false, message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!` };
        }
        
        if (error.request) {
            console.log('[error-request]:', error.request)
            return { status: false, message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!` };
        }

        console.log('[error-api]:', error.request)
        return { status: false, message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!` };
    }
}

/**
 * Function Subscribe By Hash
 * @param {json} body
 */
export async function subscribeByHash(body){
    try {
        console.log('[send-subscribe-by-hash]:', body);
        const response = await axios.post(`${URL_BACKEND}/bff/api/v2/subscription/subscribe-by-hash`, body, {headers: HEADERS });
        console.log('[success-subscribe-by-hash]:', response.data);
        return { status: true };
    } catch (error) {
        if (error.response) {
            console.log('[error-business]: ', error.response.data);
            return { status: false, message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!` };
        }
        
        if (error.request) {
            console.log('[error-request]:', error.request)
            return { status: false, message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!` };
        }

        console.log('[error-api]:', error.request)
        return { status: false, message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!` };
    }
}