import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

// Routes
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import SuporteDigitalUnSubscription from "./pages/UnSubscription";
import SuporteDigitalOffer from "./pages/Offer";
/**
 * Authorize
 */
import { isAuthorizated } from "./utils/Token";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthorizated(props) === true ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

export default () => (
  <BrowserRouter basename="/">
    <Switch>
      <Route path="/" exact={true} component={Home} />
      <PrivateRoute path="/dashboard" exact={true} component={Dashboard} />
      <PrivateRoute
        path="/cancelar"
        exact={true}
        component={SuporteDigitalUnSubscription}
      />
      <PrivateRoute
        path="/offer"
        exact={true}
        component={SuporteDigitalOffer}
      />
      <Route path="*" component={() => <Redirect to="/" />} />
    </Switch>
  </BrowserRouter>
);
