/** BACKEND ENVIRONMENT */
export const NODE_ENV = process.env.NODE_ENV;
export const BRAND_NAME = process.env.REACT_APP_BRAND_NAME;
export const REQUESTER_NAME = process.env.REACT_APP_REQUESTER_NAME;
export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
export const URL_BACKEND = process.env.REACT_APP_URL_BACKEND;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;

/** BACKEND UNSUBSCRIPTION  */
export const URL_CLOUD_FUNCTION = process.env.REACT_APP_URL_CLOUD_FUNCTION;
export const TOKEN_CLOUD_FUNCTION = process.env.REACT_APP_TOKEN_CLOUD_FUNCTION;

/** ENVIRONMENT */
export const URL_SUBSCRIPTION = process.env.REACT_APP_URL_SUBSCRIPTION;
export const URL_DOWNLOAD_MANAGER = process.env.REACT_APP_URL_DOWNLOAD_MANAGER;
export const URL_CHAT = process.env.REACT_APP_URL_CHAT;
export const URL_CHATBOT = process.env.REACT_APP_URL_CHATBOT;
export const URL_INSTAGRAM = process.env.REACT_APP_URL_INSTAGRAM;
export const URL_FACEBOOK = process.env.REACT_APP_URL_FACEBOOK;
export const URL_YOUTUBE = process.env.REACT_APP_URL_YOUTUBE;
export const URL_POLITICA_PRIVACIDADE =
  process.env.REACT_APP_URL_POLITICA_PRIVACIDADE;
export const URL_TERMOS_DE_USO_SUPORTE_DIGITAL =
  process.env.REACT_APP_URL_TERMOS_DE_USO_SUPORTE_DIGITAL;
export const URL_TERMOS_DE_USO_HERO =
  process.env.REACT_APP_URL_TERMOS_DE_USO_HERO;
export const URL_WHATSAPP = process.env.REACT_APP_URL_WHATSAPP;

/**
 * FIREBASE ENVIRONMENT
 */
export const URL_FIREBASE = process.env.REACT_APP_URL_FIREBASE;
export const API_KEY_FIREBASE = process.env.REACT_APP_API_KEY_FIREBASE;
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SEND_ID =
  process.env.REACT_APP_FIREBASE_MESSAGING_SEND_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const FIREBASE_MEASUREMENT_ID =
  process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;


  /**
   * OFFER OPATIONS
   */
export const ARRAY_OFFERS = {
  //MKT_Suporte_Digital_Protecao_3
  '0a60391451055de00a1f916b4551caa9':{
    'production': {
      'redirect': 'https://suporteherodigital.fshero.com.br/oferta/9',
      'product': 'HERO SUPORTE DIGITAL PROTECAO - 2',
      'price': '9,90'
    },
    'development': {
      'redirect': 'https://suporteherodigital.fshero.com.br/homol/oferta/9',
      'product': 'HERO SUPORTE DIGITAL PROTECAO - 2',
      'price': '9,90'
    }
  },
  //MKT_Suporte_Digital_Protecao_2
  '40c8ff7a4de38954068b7dc7ca2b1d58': {
    'production': {
      'redirect': 'https://suportedigitalhero.fshero.com.br/oferta/8',
      'product': 'HERO SUPORTE DIGITAL PROTECAO - 1',
      'price': '8,90'
    },
    'development': {
      'redirect': 'https://suportedigitalhero.fshero.com.br/homol/oferta/8',
      'product': 'HERO SUPORTE DIGITAL PROTECAO - 1',
      'price': '8,90'
    }
  },
  //MKT_Suporte_Digital_Protecao_1
  'e07144a8314ecf803a06e969677a3b7e': {
    'production': false,
    'development': false
  },
  //MKT_Suporte_Digital_Nuvem_3
  '6a6c465174875b764b3e1b1d1f1c5973': {
    'production': {
      'redirect': 'https://suporteherodigital.fshero.com.br/oferta/13',
      'product': 'HERO SUPORTE DIGITAL NUVEM - 2',
      'price': '13,90'
    },
    'development': {
      'redirect': 'https://suporteherodigital.fshero.com.br/homol/oferta/13',
      'product': 'HERO SUPORTE DIGITAL NUVEM - 2',
      'price': '13,90'
    }
  },
  //MKT_Suporte_Digital_Nuvem_2
  '483a3dccdeb04420669e09d84338d317': {
    'production': {
      'redirect': 'https://suportedigitalhero.fshero.com.br/oferta/12',
      'product': 'HERO SUPORTE DIGITAL NUVEM - 1',
      'price': '12,90'
    },
    'development': {
      'redirect': 'https://suportedigitalhero.fshero.com.br/homol/oferta/12',
      'product': 'HERO SUPORTE DIGITAL NUVEM - 1',
      'price': '12,90'
    }
  },
  //MKT_Suporte_Digital_Nuvem_1
  'cc5c949838c4bc13e88510c1e01ce7c6': {
    'production': false,
    'development': false
  },
  //MKT_Suporte_Digital_Premium_3
  '13961ba50bb6bcb1eca671f8911f65db': {
    'production': {
      'redirect': 'https://suporteherodigital.fshero.com.br/oferta/20',
      'product': 'SUPORTE DIGITAL PREMIUM - 2',
      'price': '20,90'
    },
    'development': {
      'redirect': 'https://suporteherodigital.fshero.com.br/homol/oferta/20',
      'product': 'SUPORTE DIGITAL PREMIUM - 2',
      'price': '20,90'
    }
  },
  //MKT_Suporte_Digital_Premium_2
  '947a6585931b48cf2e13906e7042e17a': {
    'production': {
      'redirect': 'https://suportedigitalhero.fshero.com.br/oferta/16-0',
      'product': 'SUPORTE DIGITAL PREMIUM - 1',
      'price': '16,90'
    },
    'development': {
      'redirect': 'https://suportedigitalhero.fshero.com.br/homol/oferta/16-0',
      'product': 'SUPORTE DIGITAL PREMIUM - 1',
      'price': '16,90'
    }
  },
  //MKT_Suporte_Digital_Premium_1
  'e319bea67d76d3ff9ab32563540303dc': false
}
