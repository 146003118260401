import React, { useState, useCallback, useEffect } from "react";
import queryString from "query-string";
import { Loader, ErrorMessage, SuccessMessage, Footer } from "../../components";
import { signatureUnSubscription } from "../../services";
import { removeToken } from "../../utils";
import IMAGE1 from "./images/logo-hero-branco.png";
import CHAT from "./images/icones-area-logada-branco-01.svg";
import WHATSAPP from "./images/icones-area-logada-branco-02.svg";
import TELEFONE from "./images/icones-area-logada-branco-03.svg";
import "./styles.css";

const SuporteDigitalUnSubscription = () => {
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const unSubscriptionProduct = useCallback(async () => {
    setLoader(true);
    setErrorMessage("");

    const params = queryString.parse(window.location.search);
    const hash = params.csi;

    const response = await signatureUnSubscription(hash);

    if (!response.status) {
      setLoader(false);
      setErrorMessage(response.message);
      return;
    }

    setLoader(false);
    setSuccessMessage("Assinatura cancelada com sucesso!");

    setTimeout(() => {
      setLoader(true);
      removeToken();
    }, 1500);
  }, []);

  useEffect(() => {
    document.body.className += " suporte-digital";
  }, []);

  return (
    <>
      <Loader display={loader} />
      <main className="suporte-digital-unsubscription fullpage">
        <section className="header">
          <div
            className="content-center-unsubscription alinhamento-central"
            style={{ paddingBottom: "60px" }}
          >
            <div>
              <header className="top alinhamento-central">
                <div className="logo">
                  <img src={IMAGE1} alt="" />
                </div>
              </header>
              <div className="boxs">
                <section className="box txt alinhamento-central">
                  <header
                    className="alinhamento-central"
                    style={{ marginBottom: "30px" }}
                  >
                    <p style={{ fontSize: "16px" }}>Poxa,</p>
                    <h1 className="title-lg">
                      Você tem certeza que deseja cancelar esse serviço?
                    </h1>
                  </header>
                  <p style={{ fontSize: "16px" }}>
                    Ao cancelar o HERO Suporte Digital você ainda poderá
                    utilizá-lo por 30 dias a partir da data do último pagamento.
                  </p>
                  <p style={{ fontSize: "16px" }}>
                    Você terá o serviço de antivírus cancelado imediatamente.
                  </p>
                  <p style={{ fontSize: "16px" }}>
                    Se você tiver arquivos no <strong>HERO Cloud</strong> é
                    preciso remover os arquivos em até 30 dias a partir da data
                    de cancelamento.{" "}
                    <strong>Após isso eles serão excluídos.</strong>
                  </p>
                  <div className="buttons">
                    <button
                      className="bt-leaked btn_manter_ativo"
                      onClick={() => (window.location.href = "/dashboard")}
                    >
                      MANTER ATIVO
                    </button>
                    <button
                      type="button"
                      className="bt cancel btn_cancelar"
                      onClick={unSubscriptionProduct}
                    >
                      CANCELAR
                    </button>
                  </div>
                  {errorMessage !== "" && (
                    <ErrorMessage display={true} message={errorMessage} />
                  )}
                  {successMessage !== "" && (
                    <SuccessMessage display={true} message={successMessage} />
                  )}

                  <div className="canais-atendimento">
                    <p style={{ fontSize: "16px" }}>
                      Para continuar com seu atendimento use nossos canais:
                    </p>

                    <ul className="opt-canais-atendimento">
                      <li>
                        <a
                          href="tel:08000003690"
                          rel="noopener noreferrer"
                          className="link_telefone"
                          target="_blank"
                        >
                          <img src={TELEFONE} alt="Mulher com Celular" />
                          <small>Telefone</small>

                          <p style={{ fontSize: "16px" }}>
                            <strong>0800-000-3690</strong>
                          </p>
                        </a>
                      </li>

                      <li>
                        <a
                          href="http://herosuportedigital.fshero.com.br/chat"
                          className="link_chat"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <img src={CHAT} alt="Mulher com Celular" />
                          <small>Chat</small>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://api.whatsapp.com/send?phone=5511943428056&text=Ol%C3%A1%2C%20venho%20do%20portal%20Suporte%20Digital"
                          className="link_whatsapp"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <img src={WHATSAPP} alt="Mulher com Celular" />
                          <small>WhatsApp</small>
                          <p style={{ fontSize: "16px" }}>
                            <strong>(11) 94342-8056</strong>
                          </p>
                        </a>
                      </li>
                    </ul>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
};

export default SuporteDigitalUnSubscription;
