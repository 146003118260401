import React, { useState, useEffect } from "react";
import {
  Loader,
  ErrorMessage,
  Footer,
  FormSendPincode,
  FormValidatePincode,
} from "../../components";

import {
  sendPincode,
  validatePincode,
  authorization,
  verifyCustomToken,
  getPacks,
  productAllPartner,
} from "../../services";
import { createToken, validatorPhoneNumber } from "../../utils";

import IMAGE1 from "./images/logo-hero-branco.png";
import "./styles.css";

const FORM_DATA = {
  phone: "",
  pincode: "",
  transactionId: "",
};

const DISPLAY = {
  sendPincode: true,
  validatePincode: false,
};

const SuporteDigitalLogin = () => {
  const [loader, setLoader] = useState(false);
  const [display, setDisplay] = useState(DISPLAY);
  const [formData, setFormData] = useState(FORM_DATA);
  const [errorMessage, setErrorMessage] = useState("");
  const [linkName, setLinkName] = useState("");
  const [counter, setCounter] = useState(0);

  const onChange = (event) => {
    setErrorMessage("");

    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmitSendPincode = async (event) => {
    event.preventDefault();

    const validator = validatorPhoneNumber(formData.phone);

    if (!validator.status) {
      setErrorMessage(validator.message);
      return;
    }

    setLoader(true);

    const response = await sendPincode({
      msisdn: validator.phone,
    });

    if (!response.status) {
      setLoader(true);
      setErrorMessage(validator.message);
      return;
    }

    setFormData({
      ...formData,
      phone: validator.phone,
      transactionId: response.transaction_id,
    });

    setLoader(false);

    setDisplay({
      ...display,
      sendPincode: false,
      validatePincode: true,
    });
  };

  const onSubmitValidatePincode = async (event) => {
    event.preventDefault();

    setLoader(true);

    const validatePincodeResponse = await validatePincode({
      transaction_id: formData.transactionId,
      pincode: formData.pincode,
    });

    if (!validatePincodeResponse.status) {
      setLoader(false);
      setErrorMessage(validatePincodeResponse.message);
      return;
    }

    const authorizationResponse = await authorization({
      "fs-auth-token": validatePincodeResponse.fs_custom_token,
    });

    if (authorizationResponse.code === 0) {
      // productAllPartner
      const verifyProduct = await productAllPartner(
        validatePincodeResponse.fs_custom_token
      );
      setLoader(false);
      setErrorMessage(verifyProduct.message);
      setLinkName(verifyProduct.link);
      return;
    } else if (!authorizationResponse.status) {
      setLoader(false);
      setErrorMessage(authorizationResponse.message);
      return;
    }

    const verifyCustomTokenResponse = await verifyCustomToken({
      token: authorizationResponse.custom_token,
      returnSecureToken: true,
    });

    if (!verifyCustomTokenResponse.status) {
      setLoader(false);
      setErrorMessage(verifyCustomTokenResponse.message);
      return;
    }
    const response = await getPacks(verifyCustomTokenResponse.id_token);

    if (!response.status) {
      setLoader(false);
      setErrorMessage(response.message);
      return;
    }

    var products = [];
    if (
      response.products.items !== undefined &&
      response.products.items.length > 0
    ) {
      products = response.products.items.filter((obj) => obj.name === "SOSSV");
    }

    if (products.length === 0) {
      // productAllPartner
      const verifyProduct = await productAllPartner(
        validatePincodeResponse.fs_custom_token
      );
      setLoader(false);
      setErrorMessage(verifyProduct.message);
      setLinkName(verifyProduct.link);
      return;
    }

    createToken(
      verifyCustomTokenResponse.id_token,
      authorizationResponse.custom_token,
      formData.phone
    );

    localStorage.setItem("msisdn", formData.phone);

    setTimeout(async () => {
      window.location.href = `/dashboard`;
    }, 3000);
  };

  const onResendPincode = async () => {
    setCounter(60);

    const response = await sendPincode({
      msisdn: formData.phone,
    });

    if (!response.status) {
      setErrorMessage(response.message);
      return;
    }

    setFormData({
      ...formData,
      transactionId: response.transaction_id,
    });
  };

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    document.body.className += " suporte-digital";
  }, []);

  return (
    <>
      <Loader display={loader} />
      <main className="suporte-digital-login fullpage">
        <section className="header">
          <div
            className="content-center-suporte-digital alinhamento-central"
            style={{ paddingBottom: "60px" }}
          >
            <header className="top">
              <div className="logo">
                <img src={IMAGE1} alt="" />
              </div>
            </header>
            <div className="boxs">
              <section className="box txt">
                {display.sendPincode && (
                  <FormSendPincode
                    phone={formData.phone}
                    onChange={onChange}
                    onSubmit={onSubmitSendPincode}
                  />
                )}
                {display.validatePincode && (
                  <FormValidatePincode
                    pincode={formData.pincode}
                    phone={formData.phone}
                    onChange={onChange}
                    onSubmit={onSubmitValidatePincode}
                  >
                    {counter > 0 ? (
                      <p
                        style={{
                          fontSize: 14,
                          marginTop: 10,
                          textDecoration: "underline",
                          color: "#ffffff",
                        }}
                      >
                        Reenviando em {counter} segundos...
                      </p>
                    ) : (
                      <p
                        style={{
                          fontSize: 14,
                          marginTop: 10,
                          textDecoration: "underline",
                          cursor: "pointer",
                          color: "#ffffff",
                        }}
                        className="btn_reenviar_pincode"
                        onClick={onResendPincode}
                      >
                        Reenviar código
                      </p>
                    )}
                  </FormValidatePincode>
                )}
                {errorMessage !== "" && (
                  <ErrorMessage
                    display={true}
                    message={errorMessage}
                    linkname={linkName}
                    className="link_suporte_sem_cadastro"
                  />
                )}
              </section>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
};

export default SuporteDigitalLogin;
