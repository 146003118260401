import React, { useEffect, useState, useCallback } from "react";
import { Menu, Loader, ErrorMessage, Footer } from "../../components";
import { getPacks } from "../../services";
import { removeToken } from "../../utils";
import {
  URL_DOWNLOAD_MANAGER,
  URL_TERMOS_DE_USO_HERO,
  URL_CHAT,
  URL_WHATSAPP,
} from "../../constants";
import IMAGE1 from "./images/logo-hero-branco.png";
import IMAGE2 from "./images/hero-suporte-homem-area-logada.png";
import IMAGE3 from "./images/icones-area-logada-branco-01.svg";
import IMAGE4 from "./images/icones-area-logada-branco-02.svg";
import IMAGE5 from "./images/icones-area-logada-branco-03.svg";
import IMAGE6 from "./images/logo-hero.png";
import Faq from "./faq.json";
import "./styles.css";

const SuporteDigital = () => {
  const [loader, setLoader] = useState(true);
  const [checked, setChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [userData, setUserData] = useState([]);
  const [carrierSubscriptionId, setCarrierSubscription] = useState("");
  const [campaignName, setCampaignName] = useState("");
  const [productsItems, setProductsItems] = useState([]);

  const getProducts = useCallback(async () => {
    const response = await getPacks();

    if (!response.status) {
      setLoader(false);
      setErrorMessage(response.message);
      return;
    }

    if (response.products.length === 0) {
      removeToken();
      return;
    }

    var list_products = [];
    response.products.items.forEach(function (item, i) {
      if (item.vendor.name === "norton") {
        list_products.push({
          id: 1,
          vendor: item.vendor.name,
          name: "",
          size: item.licenses.size,
          unit:
            item.licenses.size > 1
              ? "licença(s) Antivírus HERO para celular e desktop"
              : "licença(s) Antivírus HERO",
        });
      } else if (item.vendor.name === "fscloud") {
        list_products.push({
          id: 2,
          vendor: "",
          name: "Otimizador de performance para celular e desktop",
          size: "",
          unit: "",
        });
        list_products.push({
          id: 3,
          vendor: item.vendor.name,
          name: "CLOUD by HERO",
          size: item.licenses.size,
          unit: "GB de espaço na nuvem -",
        });
      }
    });

    list_products.sort(function (a, b) {
      return a.id - b.id;
    });
    setProductsItems(list_products);

    const vendorWithUuid =
      response.products.subscription.carrier_subscription
        .carrier_subscription_id;
    const vendor = vendorWithUuid.split("-", 1)[0];
    const uuid = vendorWithUuid.replace(vendor + "-", "");

    setCampaignName(response.products.campaign.name);
    setLoader(false);
    setUserData(response.user);
    setCarrierSubscription(uuid);
  }, []);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  useEffect(() => {
    document.body.className += " suporte-digital";
  }, []);

  return (
    <div>
      <Loader display={loader} />
      <main className="suporte-digital-dashboard">
        <section className="header" id="help">
          <div className="content-center-dashboard">
            <header className="top">
              <div className="logo">
                <a
                  href="/suporte-digital"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={IMAGE1} alt="" />
                </a>
              </div>
              <Menu />
            </header>
            <div className="boxs">
              <section className="box txt">
                <header>
                  {!!userData.name ? (
                    <h1
                      style={{ textTransform: "uppercase", fontSize: "2rem" }}
                    >
                      <span style={{ textTransform: "capitalize" }}>Olá,</span>{" "}
                      <br /> {userData.name}!
                    </h1>
                  ) : (
                    <h1
                      style={{ textTransform: "uppercase", fontSize: "2rem" }}
                    >
                      <span style={{ textTransform: "capitalize" }}>Olá!</span>
                    </h1>
                  )}
                  <h2>
                    Bem vindo ao <br /> <strong>HERO Suporte Digital</strong>
                  </h2>
                </header>
                <hr />
                <p>
                  Agora você tem tranquilidade, segurança e a comodidade do
                  Suporte Digital.
                </p>
                <div className="list-icons">
                  <ul className="lst-opt">
                    <li>
                      <a
                        href="tel:08000003690"
                        rel="noopener noreferrer"
                        className="link_telefone"
                        style={{ color: "white" }}
                        target="_blank"
                      >
                        <img src={IMAGE5} alt="" />
                        <small>Telefone</small>
                        <p>0800-000-3690</p>
                      </a>
                    </li>
                    <li>
                      <a
                        href={URL_CHAT}
                        target="_blank"
                        className="link_chat"
                        rel="noopener noreferrer"
                        style={{ color: "white", display: "grid" }}
                      >
                        <img src={IMAGE3} alt="" />
                        <small>Chat</small>
                      </a>
                    </li>
                    <li>
                      <a
                        href={URL_WHATSAPP}
                        className="link_whatsapp"
                        style={{ color: "white" }}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <img src={IMAGE4} alt="" />
                        <small>WhatsApp</small>
                        <p>(11) 94342-8056</p>
                      </a>
                    </li>
                  </ul>
                </div>
              </section>
              <div className="box img">
                <img src={IMAGE2} alt="Homem com Celular" />
              </div>
            </div>
          </div>
        </section>
        <section className="my-plan" id="my-plan">
          <div className="content-center-dashboard">
            <div className="_box">
              <p className="title">Seu plano</p>
              <section className="card-plan">
                <p className="_title">{campaignName}</p>
                <ul className="card-plan-ul">
                  <li>
                    <p>
                      - Atendimento{" "}
                      <strong style={{ fontFamily: "Lexend-Medium" }}>
                        7 dias
                      </strong>{" "}
                      por semana
                    </p>
                  </li>
                  <li>
                    <p>
                      - Consultores especializados em resolver problemas com{" "}
                      <strong style={{ fontFamily: "Lexend-Medium" }}>
                        qualquer dispositivo.
                      </strong>
                    </p>
                  </li>
                  {productsItems.map((product, i) => {
                    return (
                      <li key={product.id}>
                        <p>
                          -{" "}
                          <strong style={{ fontFamily: "Lexend-Medium" }}>
                            {product.size} {product.unit} {product.name}
                          </strong>
                        </p>
                      </li>
                    );
                  })}
                </ul>
                {errorMessage !== "" && (
                  <ErrorMessage display={true} message={errorMessage} />
                )}
              </section>
            </div>
            <div className="_box border-box second">
              <p className="title">Seus benefícios</p>
              {productsItems.length > 0 && (
                <div>
                  <img src={IMAGE6} className="_logo" alt="" />
                  <p>
                    Além de um suporte especializado você tem direito ao{" "}
                    <strong style={{ fontFamily: "Lexend-Medium" }}>
                      HERO Antivírus
                    </strong>
                    .
                  </p>
                  <a
                    href={
                      URL_DOWNLOAD_MANAGER +
                      `/hero/delivery?produto=pom&serial=${userData.id}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bt link_hero_baixe_agora btn_hero_baixe_agora"
                    disabled={!checked ? true : false}
                  >
                    Baixe e aproveite agora
                  </a>

                  <div className="terms-check">
                    <p style={{ fontSize: 14 }}>
                      <input
                        type="checkbox"
                        style={{
                          border: 2,
                          dotted: "#00f",
                          background: "#ff0000",
                          appearance: "auto",
                        }}
                        name="checked"
                        required
                        onChange={() => setChecked(!checked)}
                        defaultChecked={checked}
                      />{" "}
                      Li e aceito os{" "}
                      <a
                        href={URL_TERMOS_DE_USO_HERO}
                        rel="noopener noreferrer"
                        target="_blank"
                        className="link_hero_termo_uso"
                        style={{
                          fontSize: 14,
                          textDecoration: "underline",
                        }}
                      >
                        Termos de uso e Privacidade
                      </a>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
        <section className="faq" id="faq">
          <div className="content-center-dashboard">
            <header>
              <p>
                Nosso time está a disposição para resolver os mais diversos
                problemas e dúvidas relacionadas a seus dispositivos.
              </p>
              <p>Use quantas vezes precisar sem custo adicional.</p>
              <p>
                <strong style={{ fontFamily: "Lexend-Medium" }}>
                  Como podemos te ajudar hoje?
                </strong>
              </p>
            </header>
            <ul className="list-faq">
              {Faq.map((obj, key) => {
                return (
                  <li key={key}>
                    <details>
                      <summary className={obj.class}>{obj.question}</summary>
                      <div className="content-details">
                        <p style={{ whiteSpace: "pre-line" }}>{obj.answer}</p>
                      </div>
                    </details>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>
        <Footer
          buttonUnsubscribe
          carrierSubscriptionId={carrierSubscriptionId}
        />
      </main>
    </div>
  );
};

export default SuporteDigital;
