import React from "react";

import {
  URL_INSTAGRAM,
  URL_FACEBOOK,
  URL_YOUTUBE,
  URL_POLITICA_PRIVACIDADE,
  URL_TERMOS_DE_USO_SUPORTE_DIGITAL,
} from "../../../constants";

import IMAGE3 from "./images/logo-hero-suporte-digital.png";
import IMAGE4 from "./images/Icones-redes-sociais-verde-01.svg";
import IMAGE5 from "./images/Icones-redes-sociais-verde-02.svg";
import IMAGE6 from "./images/Icones-redes-sociais-verde-03.svg";
import "./styles.css";

const FooterSuporteDigital = ({ buttonUnsubscribe, carrierSubscriptionId }) => {
  return (
    <footer className="sd-footer">
      <div className="content-center">
        <div className="logo">
          <a href="/" target="_blank" rel="noopener noreferrer">
            <img src={IMAGE3} alt="" />
          </a>
        </div>
        <div className="copy">
          <small>@ FS Security 2021 - Todos os direitos reservados</small>
          <ul className="list-links">
            <li>
              <a
                href={URL_TERMOS_DE_USO_SUPORTE_DIGITAL}
                rel="noopener noreferrer"
                className="link_lgpd link_termo_uso_login"
                target="_blank"
              >
                Termos de uso
              </a>
            </li>
            <li>
              <a
                href={URL_POLITICA_PRIVACIDADE}
                rel="noopener noreferrer"
                target="_blank"
                className="link_portal_privacidade_login"
              >
                Portal de Privacidade
              </a>
            </li>
            {buttonUnsubscribe && (
              <li>
                <a
                  href={`/offer?csi=${carrierSubscriptionId}`}
                  rel="noopener noreferrer"
                  style={{ color: "#d40c0c" }}
                  className="link_cancelar_assinatura"
                >
                  Cancelar Assinatura
                </a>
              </li>
            )}
          </ul>
        </div>
        <div className="social">
          <p>
            Siga o <br />{" "}
            <strong style={{ fontFamily: "Lexend-Bold" }}>
              HERO Suporte Digital
            </strong>
          </p>
          <ul className="list-social">
            <li>
              <a
                href={URL_INSTAGRAM}
                target="_blank"
                rel="noopener noreferrer"
                className="link_instagram link_instagram_login"
              >
                <img src={IMAGE6} alt="" />
              </a>
            </li>
            <li>
              <a
                href={URL_YOUTUBE}
                target="_blank"
                rel="noopener noreferrer"
                className="link_youtube link_youtube_login"
              >
                <img src={IMAGE4} alt="Youtube" />
              </a>
            </li>
            <li>
              <a
                href={URL_FACEBOOK}
                target="_blank"
                rel="noopener noreferrer"
                className="link_facebook link_facebook_login"
              >
                <img src={IMAGE5} alt="" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default FooterSuporteDigital;
