import axios from "axios";
import uuidv4 from "uuid/v4";

import { validateToken } from "../../utils/Token";

import {
  URL_BACKEND,
  BRAND_NAME,
  REQUESTER_NAME,
  URL_CHAT,
  URL_SUBSCRIPTION
} from "../../constants";

export async function productAllPartner(token) {
  let HEADERS = {
    "Content-Type": "application/json",
    "X-FS-Correlation-ID": uuidv4(),
    "X-FS-Requester-Name": "owner",
    "X-FS-Brand-Name": BRAND_NAME,
    Authorization: `Bearer ${token}`,
  };
  let message = `Ops, você não possui o produto hero suporte digital! compre ${URL_SUBSCRIPTION} mas se precisa de nossa ajuda clique ${URL_CHAT}`
  let link = ['agora', null, 'aqui']

  try {
    const response = await axios.get(`${URL_BACKEND}/bff/api/v2/user/search/msisdn/products`, {
      headers: HEADERS,
    })

    if (Object.keys(response.data.fastshop).length > 0) {
      return {
        status: false,
        message: `Ops.você não possui o produto hero suporte digital! Clique ${URL_CHAT} para falar conosco`,
      };
    }

    Object.entries(response.data.product).forEach(entry => {
      const [key, value] = entry;

      Object.entries(value).forEach(brand => {
        if (brand[1].hasOwnProperty('assistencia')) {
          if (brand[1].assistencia.status === 'ENABLED') {
            message = `Ops, você não possui o produto hero suporte digital! compre ${URL_SUBSCRIPTION} mas se precisa de nossa ajuda clique ${URL_CHAT}`
            link = ['agora', key, 'aqui']
          }
        }
      });

    });
  } catch (error) {
    if (error.response.status && error.response.status !== 404) {
      message = `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!`
      link = null
    }
  }

  return {
    status: false,
    message: message,
    link: link
  }
}

/**
 * Function Get Packs
 */
export async function getPacks(idToken) {
  let HEADERS = {
    "Content-Type": "application/json",
    "X-FS-Correlation-ID": uuidv4(),
    "X-FS-Requester-Name": REQUESTER_NAME,
    "X-FS-Brand-Name": BRAND_NAME,
    Authorization: `Bearer ${validateToken("token")}`,
  };

  if (idToken) {
    HEADERS = {
      "Content-Type": "application/json",
      "X-FS-Correlation-ID": uuidv4(),
      "X-FS-Requester-Name": REQUESTER_NAME,
      "X-FS-Brand-Name": BRAND_NAME,
      Authorization: `Bearer ${idToken}`,
    };
  }

  try {
    console.log("[get-packs]:", BRAND_NAME);

    const response = await axios.get(`${URL_BACKEND}/bff/api/v2/packs`, {
      headers: HEADERS,
    });

    let products = [];

    if (response.data.products.length === 0) {
      return { status: true, products: [] };
    }

    response.data.products.forEach((rows) => {
      if (rows.subscription.status === "ACTIVE") {
        products.push(rows);
      }
    });

    products = products.filter((obj) => {
      return (
        obj.items.filter((item) => {
          return item.name === "SOSSV";
        }).length === 1
      );
    });

    console.log("[success-packs]:", products[0]);
    return {
      status: true,
      products: products[0],
      user: response.data.user,
      customToken: validateToken("customToken"),
    };
  } catch (error) {
    if (error.response) {
      console.log("[error-business]: ", error.response.data);
      return {
        status: false,
        message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!`,
      };
    } else if (error.request) {
      console.log("[error-request]:", error.request);
      return {
        status: false,
        message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!`,
      };
    } else {
      console.log("[error-api]:", error.request);
      return {
        status: false,
        message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!`,
      };
    }
  }
}

/**
 * Function Get Packs Callcenter
 */
export async function getPackCallCenter(msisdn) {
  try {
    const response = await axios.get(
      `${URL_BACKEND}/bff/api/v2/packs/msisdn/${msisdn}/?canceled=true`,
      {
        headers: {
          "Content-Type": "application/json",
          "X-FS-Brand-Name": "hero",
          "X-FS-Requester-Name": "postman",
          "X-FS-Correlation-ID": uuidv4(),
        },
      }
    );
    console.log("[success-packs]:", response.data);
    return {
      status: true,
      user: response.data.user,
      products: response.data.products,
    };
  } catch (error) {
    if (error.response) {
      console.log("[error-business]: ", error.response.data);
      return {
        status: false,
        message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!`,
      };
    } else if (error.request) {
      console.log("[error-request]:", error.request);
      return {
        status: false,
        message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!`,
      };
    } else {
      console.log("[error-api]:", error.request);
      return {
        status: false,
        message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!`,
      };
    }
  }
}
