import React, { useState, useCallback, useEffect } from "react";
import MD5 from 'crypto-js/md5'
import queryString from "query-string";
import { Loader, ErrorMessage, SuccessMessage, Footer } from "../../components";
import { signatureUnSubscription } from "../../services";
import { getPacks } from "../../services";
import { removeToken } from "../../utils";

import { ARRAY_OFFERS } from "../../constants";

import IMAGE1 from "./images/logo-hero-branco.png";
import IMAGE2 from "./images/hero-homem-downsell.png";
import "./styles.css";

const SuporteDigitalOffer = () => {
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [productsItems, setProductsItems] = useState([]);
  const [hubspotRedirectURL, setHubspotRedirectURL] = useState([]);
  const [priceProduct, setPriceProduct] = useState([]);

  const getOfferOptions = useCallback(async () => {
    setLoader(true);
    const response = await getPacks();

    if (!response.status) {
      setLoader(false);
      setErrorMessage(response.message);
      return;
    }

    if (response.products.length === 0) {
      removeToken();
      return;
    }

    var list_products = [];
    response.products.items.forEach(function (item, i) {
      if (item.vendor.name === "norton") {
        list_products.push({
          id: 1,
          vendor: item.vendor.name,
          name: "",
          size: item.licenses.size,
          unit:
            item.licenses.size > 1
              ? "licença(s) Antivírus HERO para celular e desktop"
              : "licença(s) Antivírus HERO",
        });
      } else if (item.vendor.name === "fscloud") {
        list_products.push({
          id: 2,
          vendor: "",
          name: "Otimizador de performance para celular e desktop",
          size: "",
          unit: "",
        });
        list_products.push({
          id: 3,
          vendor: item.vendor.name,
          name: "",
          size: item.licenses.size,
          unit: "GB de espaço na nuvem",
        });
      }
    });

    list_products.sort(function (a, b) {
      return a.id - b.id;
    });
    setProductsItems(list_products);
    const identifierDescription = response.products.identifier.description
    const vendorWithUuid =
      response.products.subscription.carrier_subscription
        .carrier_subscription_id;
    const vendor = vendorWithUuid.split("-", 1)[0];
    const uuid = vendorWithUuid.replace(vendor + "-", "");
    
    let hashIdentifier = MD5(identifierDescription).toString()

    let redirectURL
    
    console.log(identifierDescription, hashIdentifier)

    let env = 'development'
    if (process.env.NODE_ENV === 'production') 
      env = process.env.NODE_ENV

    if (ARRAY_OFFERS[hashIdentifier][env] === false || ARRAY_OFFERS[hashIdentifier][env] === undefined) {
      window.location.href = `/cancelar?csi=${uuid}`
    } else {
      redirectURL = ARRAY_OFFERS[hashIdentifier][env].redirect
      setPriceProduct(ARRAY_OFFERS[hashIdentifier][env].price)
      setLoader(false)
    }

    redirectURL+=`?csi=${uuid}`
    setHubspotRedirectURL(redirectURL)
    console.log(redirectURL)

  }, []);

  const unSubscriptionProduct = useCallback(async () => {
    setLoader(true);
    setErrorMessage("");

    const params = queryString.parse(window.location.search);
    const hash = params.csi;

    const response = await signatureUnSubscription(hash);

    if (!response.status) {
      setLoader(false);
      setErrorMessage(response.message);
      return;
    }

    setLoader(false);
    setSuccessMessage("Assinatura cancelada com sucesso!");

    setTimeout(() => {
      setLoader(true);
      removeToken();
    }, 1500);
  }, []);

  useEffect(() => {
    getOfferOptions();
  }, [getOfferOptions]);

  useEffect(() => {
    document.body.className += " suporte-digital";
  }, []);

  return (
    <>
      <Loader display={loader} />
      <main className="suporte-digital-unsubscription fullpage">
        <section className="header">
          <div
            className="content-center-unsubscription"
            style={{ paddingBottom: "0px" }}
          >
            <div>
              <div className="boxs">
                <section className="box txt"  style={{ fontSize: "14px" }}>
                  <header className="top alinhamento-central">
                    <div className="logo linhamento-central">
                      <img src={IMAGE1} alt=""/>
                    </div>
                  </header>
                  <header className="">
                    <p style={{ fontSize: "14px" }}>Voc&ecirc; pode continuar com:</p>
                  </header>
                  <ul className="">
                    <li>
                        Atendimento 7 dias por semana
                    </li>
                    <li>
                        Consultores especializados em resolver problemas com qualquer dispositivo.
                    </li>
                    {productsItems.map((product, i) => {
                      return (
                        <li key={product.id}>
                          {product.size} {product.unit} {product.name}
                        </li>
                      );
                    })}
                  </ul>
                  <header>
                    <p style={{ fontSize: "14px", border: "1px solid white", borderRadius: "4px", padding: "20px", marginBottom: "10px" }}>
                      Ent&atilde;o, tenho uma proposta para voc&ecirc;<br></br>
                      Que tal permanecer com a gente? Voc&ecirc; ter&aacute; acesso aos mesmos<br></br>
                      benef&iacute;cios pelo valor abaixo:
                    </p>
                  </header>
                  <p style={{ fontSize: "18px", padding: "0" }}><strong>Apenas R$ {priceProduct} mensais</strong></p>
                  <div className="buttons">
                    <button
                      type="button"
                      className="bt cancel btn_cancelar cancelar_manter_ativo"
                      onClick={() => (window.location.href = hubspotRedirectURL)}
                    >
                      Aceitar oferta
                    </button>
                    <button
                      className="bt-leaked btn_manter_ativo cancelar_confirmar"
                      onClick={unSubscriptionProduct}
                    >
                      Cancelar
                    </button>
                    
                  </div>
                  {errorMessage !== "" && (
                    <ErrorMessage display={true} message={errorMessage} className="cancelar_erro" />
                  )}
                  {successMessage !== "" && (
                    <SuccessMessage display={true} message={successMessage} />
                  )}
                </section>
                <div className="box img">
                  <img src={IMAGE2} alt="Homem com Celular"style={{ width: "100%" }} />
                </div>
              </div>
            </div>
          </div>
          
        </section>
        
        <Footer />
      </main>
    </>
  );
};

export default SuporteDigitalOffer;
