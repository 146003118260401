import React, { useState } from "react";
import { validateToken, removeToken } from "../../../utils";
import Loader from "../../Presentational/Loader";

const Menu = () => {
  const [menu, setMenu] = useState(false);
  const [loader, setLoader] = useState(false);

  const onClickButtonCloseInfoHelpBot = () => {
    document.querySelector("chatbot").style.display = "block";
    setTimeout(() => document.getElementById("chat-icon").click(), 60);
    setMenu(false);
  };

  const onClickLogout = () => {
    if (validateToken("token")) {
      setLoader(true);

      setTimeout(function () {
        removeToken();
      }, 3000);
    }
  };

  return (
    <>
      <button
        className="bt-leaked btn_meu_perfil"
        onClick={() => setMenu(true)}
      >
        Meu Perfil
      </button>
      <div
        className={`mask-menu ${!menu && `hide`}`}
        onClick={() => setMenu(false)}
      />
      <section className={`menu-mobile ${!menu && `hide`}`}>
        <header>
          <h1>Meu perfil</h1>
          <span className="close-menu" onClick={() => setMenu(false)}>
            x
          </span>
        </header>
        <ul className="list-menu">
          <li>
            <a
              href="#my-plan"
              className="btn_minha_assinatura meu_perfil_assinatura"
              onClick={() => setMenu(false)}
            >
              Minha Assinatura
            </a>
          </li>
          <li>
            <a
              href="#faq"
              className="btn_servicos_atendidos meu_perfil_servicos"
              onClick={() => setMenu(false)}
            >
              Serviços Atendidos
            </a>
          </li>
          <li>
            <a
              href="#help"
              className="btn_ajuda meu_perfil_ajuda"
              onClick={onClickButtonCloseInfoHelpBot}
            >
              Ajuda
            </a>
          </li>
          <li>
            <p
              style={{ cursor: "pointer", color: "#37b3a7" }}
              className="btn_logout meu_perfil_sair"
              onClick={onClickLogout}
            >
              Sair
            </p>
          </li>
        </ul>
        <Loader display={loader} />
      </section>
    </>
  );
};

export default Menu;
