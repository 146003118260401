import React from "react";
import reactStringReplace from "react-string-replace";
import "./styles.css";

export default (props) => {
  let linkName = 'aqui'
  return (
    <div
      className="div-error"
      style={{ display: `${props.display ? "block" : "none"}` }}
    >
      <div className="close-error" onClick={props.onCloseErrorMessage}>
        X
      </div>
      <div>
        {reactStringReplace(props.message, /(https?:\/\/\S+)/g, (match, i) => (
          <a
            key={match + i}
            rel="noopener noreferrer"
            target="_blank"
            href={match}
          >
            {linkName = props.linkname ? props.linkname[i - 1] : linkName}
          </a>
        ))}
      </div>
    </div>
  );
};
