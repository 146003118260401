import axios from 'axios'

import {
    URL_CLOUD_FUNCTION,
    TOKEN_CLOUD_FUNCTION,
    URL_CHAT,
} from '../../constants'

const HEADERS = {
    'Authorization': TOKEN_CLOUD_FUNCTION,
}

export async function signatureUnSubscription(carrierSubscriptionId) {
    try {
        console.log('send-signature-unsubscription', carrierSubscriptionId);
        const response = await axios.delete(
            `${URL_CLOUD_FUNCTION}/signature-subscription/${carrierSubscriptionId}`,
            {
              headers: HEADERS,
            }
          );

        console.log('success-send-signature-unsubscription', response.data);
        return { status: true }

    } catch (error) {
        if (error.response) {
            console.log('[error-business]: ', error.response.data);
            return { status: false, message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!` };
        } else if (error.request) {
            console.log('[error-request]:', error.request)
            return { status: false, message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!` };
        } else {
            console.log('[error-api]:', error.request)
            return { status: false, message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!` };
        }
    }
}