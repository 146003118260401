import React from "react";
import NumberFormat from "react-number-format";
import "./styles.css"

const FormSendPincode = ({ onSubmit, onChange, phone }) => {
  return (
    <>
      <header className="alinhamento-central">
        <p style={{fontSize: "16px"}}>Acesse o</p>
        <h1 className="title-lg">HERO Suporte Digital</h1>
      </header>
      <p style={{fontSize: "14px"}}>Para entrar insira o número de celular com o DDD, utilizado no momento da compra.</p>

      <form className="cell-pincode alinhamento-central" onSubmit={onSubmit}>
        <NumberFormat
          value={phone}
          onChange={onChange}
          type="text"
          name="phone"
          placeholder="(xx) xxxxx-xxxx"
          required
          format="(##) #####-####"
          mask="_"
          autoFocus={true}
        />

        <button type="submit" className="bt btn_entrar_msisdn">
          Entrar
        </button>
      </form>
    </>
  );
};

export default FormSendPincode;
