import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { URL_TERMOS_DE_USO_SUPORTE_DIGITAL } from "../../../../constants";

import "./styles.css";

const FormValidatePincode = ({
  children,
  phone,
  pincode,
  onChange,
  onSubmit,
}) => {
  const [checked, setChecked] = useState(false);
  return (
    <>
      <header className="alinhamento-central">
        <p style={{ fontSize: "20px", fontFamily: "Lexend-Bold" }}>
          <strong>Fique atento</strong>
        </p>
      </header>
      <p style={{ fontSize: "16px", lineHeight: "35px" }}>
        Insira o código de <strong>6 dígitos</strong> enviado para o número:{" "}
        <br /> <strong style={{ fontFamily: "Lexend-Bold" }}>+{phone}</strong>
      </p>
      <form
        className="sd-validate-pincode alinhamento-central"
        onSubmit={onSubmit}
      >
        <div className="alinhamento-central flex-collumn">
          <NumberFormat
            value={pincode}
            onChange={onChange}
            name="pincode"
            placeholder="••• •••"
            required
            format="######"
            mask=""
            autoFocus={true}
            autoComplete="off"
          />
          <button
            type="submit"
            className="bt btn_enviar_pincode btn_validar_pincode"
          >
            Enviar
          </button>
        </div>
        {children}
        <div className="terms-check">
          {/* <input
              type="checkbox"
              name="checked"
              required
              onChange={() => setChecked(!checked)}
              defaultChecked={checked}
            />  */}

          <label htmlFor="toggle" style={{ position: "relative" }}>
            <input
              type="checkbox"
              id="toggle"
              className="aceite_termo_uso"
              name="checked"
              required
              onChange={() => setChecked(!checked)}
              defaultChecked={checked}
            />
            <span className="toogl"></span>
          </label>
          <p style={{ fontSize: 16, color: "white", marginLeft: "10px" }}>
            Ao clicar em <b>ENVIAR</b>, confirmo que estou de acordo com os{" "}
            <br />{" "}
            <a
              href={URL_TERMOS_DE_USO_SUPORTE_DIGITAL}
              rel="noopener noreferrer"
              target="_blank"
              className="link_termo_uso_pincode"
              style={{
                fontSize: 16,
                color: "white",
                textDecoration: "underline",
              }}
            >
              Termos de uso e Privacidade
            </a>
          </p>
        </div>
      </form>
    </>
  );
};

export default FormValidatePincode;
