import axios from "axios";
import uuidv4 from "uuid/v4";

import {
  URL_BACKEND,
  BRAND_NAME,
  REQUESTER_NAME,
  CLIENT_ID,
  CLIENT_SECRET,
  URL_CHAT,
} from "../../constants";

const HEADERS = {
  "Content-Type": "application/json",
  "X-FS-Correlation-ID": uuidv4(),
  "X-FS-Requester-Name": REQUESTER_NAME,
  "X-FS-Brand-Name": BRAND_NAME,
  client_id: CLIENT_ID,
  client_secret: CLIENT_SECRET,
  "X-FS-Is-BFF": "true",
};

/**
 * Function Authorize FS Auth Token
 * @param {json} body
 * @param {string} brand
 * @param {string} partner
 * @param {bool} forceMerge
 */
export async function authorization(body, partner, forceMerge) {
  try {
    console.log("[send-authorization]:", body);

    let url =
      partner === "VIVO FIXA"
        ? `?extref_fixo=true&data_validated=${forceMerge}`
        : `?extref_fixo=false&data_validated=${forceMerge}`;
    let response = await axios.post(
      `${URL_BACKEND}/authorization/api/v1/authorize${url}`,
      body,
      { headers: HEADERS }
    );
    console.log(
      "[validate-authorization]:",
      response.data.authorization.custom_token
    );
    return {
      status: true,
      custom_token: response.data.authorization.custom_token,
    };
  } catch (error) {
    if (error.response) {
      console.log("[error-business]: ", error.response.data);
      return {
        status: false,
        message: `Ops, você não tem esse produto cadastrado. Clique ${URL_CHAT} para falar conosco!`,
        code: 0
      };
    } else if (error.request) {
      console.log("[error-request]:", error.request);
      return {
        status: false,
        message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!`,
      };
    } else {
      console.log("[error-api]:", error.request);
      return {
        status: false,
        message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!`,
      };
    }
  }
}

/**
 * Function Authorize CPF
 * @param {json} body
 */
export async function authorizeCPF(body) {
  try {
    console.log("[send-validate-cpf]:", body);
    let response = await axios.post(
      `${URL_BACKEND}/authorization/api/v1/authorize/cpf`,
      body,
      { headers: HEADERS }
    );
    console.log("[success-cpf-auth-token]:", response.data["fs-auth-token"]);
    return { status: true, cpfcnpj_auth_token: response.data["fs-auth-token"] };
  } catch (error) {
    if (error.response) {
      console.log("[error-business]: ", error.response.data);
      return {
        status: false,
        message: `Ops, você não tem esse produto cadastrado. Clique ${URL_CHAT} para falar conosco!`,
      };
    } else if (error.request) {
      console.log("[error-request]:", error.request);
      return {
        status: false,
        message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!`,
      };
    } else {
      console.log("[error-api]:", error.request);
      return {
        status: false,
        message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!`,
      };
    }
  }
}

/**
 * Function Authorize CNPJ
 * @param {json} body
 */
export async function authorizeCNPJ(body) {
  try {
    console.log("[send-cnpj]:", body);
    let response = await axios.post(
      `${URL_BACKEND}/authorization/api/v1/authorize/cnpj`,
      body,
      { headers: HEADERS }
    );
    console.log("[success-cnpj-auth-token]:", response.data["fs-auth-token"]);
    return { status: true, cpfcnpj_auth_token: response.data["fs-auth-token"] };
  } catch (error) {
    if (error.response) {
      console.log("[error-business]: ", error.response.data);
      return {
        status: false,
        message: `Ops, você não tem esse produto cadastrado. Clique ${URL_CHAT} para falar conosco!`,
      };
    } else if (error.request) {
      console.log("[error-request]:", error.request);
      return {
        status: false,
        message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!`,
      };
    } else {
      console.log("[error-api]:", error.request);
      return {
        status: false,
        message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!`,
      };
    }
  }
}
